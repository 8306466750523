class FavoritiesItemPage extends  Component {

    static name(){
        return "favoritiesItemPage";
    }

    static componentName() {
        return 'favoritiesItemPage';
    }

    goBack(){
        this.$router.go(-1);
    }

    getMethods(){
        return{
            goBack:this.goBack
        };
    }

    getTemplate(){
        return `<div>
                  <headerSectionComponent></headerSectionComponent>
                  <div :class="classContainer">
                      <div class="section-main">
                         <div class="row">
                             <template v-if="isLoading">
                                  <div class="loading">
                                      <i class="fas fa-spinner fa-spin fa-3x"></i>
                                  </div>
                             </template>
                             <template v-else>
                                   <div class="col-10 offset-1">
                                      <favoritiesListComponent></favoritiesListComponent>
                                  </div>
                            </template>
                         </div>
                      </div>
                  </div>
                  <footerSectionComponent></footerSectionComponent>
                </div>`;
    }
}
FavoritiesItemPage.registerComponent();
